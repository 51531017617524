import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import {
  Container,
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  CircularProgress
} from '@mui/material';
import './App.css';

const fetchFromLocalStorage = (key, defaultValue) => {
  return JSON.parse(localStorage.getItem(key)) || defaultValue;
};

const useLocalStorageState = (key, defaultValue) => {
  const [state, setState] = useState(fetchFromLocalStorage(key, defaultValue));
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);
  return [state, setState];
};

const useFadeAnimation = (value) => {
  const [fadeClass, setFadeClass] = useState('');

  useEffect(() => {
    setFadeClass('fade');
    const timeoutFade = setTimeout(() => setFadeClass(''), 500);
    return () => clearTimeout(timeoutFade);
  }, [value]);

  return fadeClass;
};

const App = () => {
  const [eurReceivedToday] = useLocalStorageState('eurReceivedToday', 20.00);
  const [nexoTokens, setNexoTokens] = useLocalStorageState('nexoTokens', 0);
  const [nexoTokenPrice, setNexoTokenPrice] = useLocalStorageState('nexoTokenPrice', 0);
  const [eurPrice, setEurPrice] = useLocalStorageState('eurPrice', 0);
  const [nexoTokensEur, setNexoTokensEur] = useLocalStorageState('nexoTokensEur', 0);
  const [nexoTokensBreakeven, setNexoTokensBreakeven] = useLocalStorageState('nexoTokensBreakeven', 0);
  const [nexoTokensData, setNexoTokensData] = useLocalStorageState('nexoTokensData', []);
  const [gains, setGains] = useLocalStorageState('gains', 0);
  const [losses, setLosses] = useLocalStorageState('losses', 0);
  const [cycleStarted, setCycleStarted] = useLocalStorageState('cycleStarted', false);
  const [daysPassed, setDaysPassed] = useLocalStorageState('daysPassed', 0);
  const [globalTimeout] = useLocalStorageState('globalTimeout', 600);
  const [remainingTime, setRemainingTime] = useState(600);

  const chartRef = useRef(null);

  const fetchNexoTokenPrice = async () => {
    try {
      const response = await axios.get(
        'https://min-api.cryptocompare.com/data/price?fsym=NEXO&tsyms=USD,EUR,USDT'
      );
      setNexoTokenPrice(response.data.USDT);
      setEurPrice(response.data.EUR);
    } catch (error) {
      console.error('Error fetching Nexo token price:', error);
    }
  };

  useEffect(() => {
    fetchNexoTokenPrice();
    const priceInterval = setInterval(fetchNexoTokenPrice, 2000);
    return () => clearInterval(priceInterval);
  });

  const tokensReceivedToday = useMemo(() => {
    return eurPrice ? eurReceivedToday / eurPrice : 0;
  }, [eurReceivedToday, eurPrice]);

  const handleCycle = useCallback(() => {
    const updateChartData = (balance) => {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}-${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

      setNexoTokensData(prevData => {
        const newData = [...prevData, { x: formattedDate, y: balance.toFixed(2) }];
        localStorage.setItem('nexoTokensData', JSON.stringify(newData));
        return newData;
      });
    };

    setNexoTokens(prevTokens => prevTokens + tokensReceivedToday);
    setNexoTokensEur(prevTotal => prevTotal + (tokensReceivedToday * eurPrice));
    setNexoTokensBreakeven(prevTotal => prevTotal + eurReceivedToday);
    setDaysPassed(prevTotal => prevTotal + 1);
    updateChartData((nexoTokens + tokensReceivedToday) * eurPrice);
  }, [tokensReceivedToday, eurPrice, eurReceivedToday, nexoTokens, setDaysPassed, setNexoTokens, setNexoTokensBreakeven, setNexoTokensEur, setNexoTokensData]);

  useEffect(() => {
    if (cycleStarted) {
      const interval = setInterval(handleCycle, globalTimeout * 1000);
      return () => clearInterval(interval);
    }
  }, [cycleStarted, handleCycle, globalTimeout]);

  useEffect(() => {
    let timer;
    if (cycleStarted) {
      timer = setInterval(() => {
        setRemainingTime(prevTime => (prevTime > 1 ? prevTime - 1 : globalTimeout));
      }, 1000);
    } else {
      setRemainingTime(globalTimeout);
    }
    return () => clearInterval(timer);
  }, [cycleStarted, globalTimeout]);

  const startCycle = () => {
    setCycleStarted(true);
    setNexoTokens(0);
    setNexoTokensData([]);
    setDaysPassed(0);
    setNexoTokensEur(0);
    setNexoTokensBreakeven(0);
  };

  const sellNexoTokens = () => {
    const amountReceived = nexoTokens * eurPrice;
    const breakevenAmountReceived = eurReceivedToday * daysPassed;

    if (amountReceived > breakevenAmountReceived) {
      setGains(amountReceived - breakevenAmountReceived);
      setLosses(0);
    } else {
      setLosses(breakevenAmountReceived - amountReceived);
      setGains(0);
    }
    setNexoTokens(0);
    setCycleStarted(false);
    setNexoTokensData([]);
    setDaysPassed(0);
    setNexoTokensEur(0);
    setNexoTokensBreakeven(0);
  };

  function CircularProgressWithLabel(props) {
    const { value } = props;
    const timeRemaining = (value);

    const getTimeDisplay = (time) => {
      if (time >= 600) {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      } else {
        return `${Math.round(time)}s`;
      }
    };

    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {getTimeDisplay(timeRemaining)}
          </Typography>
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    if (nexoTokensData.length > 0) {
      const renderChart = () => {
        if (chartRef.current) {
          const ctx = chartRef.current.getContext('2d');
          if (window.myChart) {
            window.myChart.destroy();
          }

          const breakevenData = nexoTokensData.map((dataPoint, index) => ({
            x: dataPoint.x,
            y: (eurReceivedToday * (index + 1)).toFixed(2)
          }));

          window.myChart = new Chart(ctx, {
            type: 'line',
            data: {
              datasets: [
                {
                  label: 'Balance (EUR)',
                  data: nexoTokensData,
                  borderColor: '#1c4cdc',
                  backgroundColor: '#1c4cdc',
                  tension: 0.4,
                  fill: false,
                  yAxisID: 'y1'
                },
                {
                  label: 'Breakeven',
                  data: breakevenData,
                  borderColor: '#04a46c',
                  backgroundColor: '#04a46c',
                  tension: 0.4,
                  fill: '-1',
                  yAxisID: 'y2'
                }
              ]
            },
            options: {
              // rest of the chart configuration
            }
          });

          const chartArea = window.myChart.chartArea;
          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradient.addColorStop(0, 'rgba(75, 192, 192, 0.2)');
          gradient.addColorStop(1, 'rgba(255, 99, 132, 0.2)');
          window.myChart.update();
        }
      };

      renderChart();
    }
  }, [nexoTokensData, eurReceivedToday]);

  const fadeClassNexoTokens = useFadeAnimation(nexoTokens);
  const fadeClassNexoTokensEur = useFadeAnimation(nexoTokensEur);
  const fadeClassNexoTokensBreakeven = useFadeAnimation(nexoTokensBreakeven);
  const fadeClassProfitLoss = useFadeAnimation((nexoTokens * eurPrice).toFixed(2) - (eurReceivedToday * daysPassed).toFixed(2));
  const fadeClassNexoTokenPrice = useFadeAnimation(nexoTokenPrice);

  return (
    <Container maxWidth="md" sx={{ backgroundColor: '#1c4cdc', py: 4 }}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            zIndex: 1,
          }}
        >
          <Typography variant="h2" component="div" color="white">
            NEXO Tracker
          </Typography>
        </Box>
      </Grid>
    </Grid>      
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h7">Nexo Balance</Typography>
              <Typography variant="h6" className={fadeClassNexoTokens}>{nexoTokens.toFixed(2)} NEXO</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h7">Nexo Balance (€)</Typography>
              <Typography variant="h6" className={fadeClassNexoTokensEur}>{nexoTokensEur.toFixed(2)} €</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h7">Breakeven Balance</Typography>
              <Typography variant="h6" className={fadeClassNexoTokensBreakeven}>{nexoTokensBreakeven.toFixed(2)} €</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h7">Profit / Loss</Typography>
              <Typography variant="h6" className={fadeClassProfitLoss} sx={{ color: ((nexoTokens * eurPrice).toFixed(2) - (eurReceivedToday * daysPassed).toFixed(2)) >= 0 ? 'green' : 'red' }}>
                {((nexoTokensEur).toFixed(2) - (eurReceivedToday.toFixed(2) * daysPassed)).toFixed(2)} €
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h7">Nexo Price ($)</Typography>
              <Typography variant="h6" className={fadeClassNexoTokenPrice}>{nexoTokenPrice} $</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h7">Nexo Price</Typography>
              <Typography variant="h6">{eurPrice} €</Typography>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={5}>
            <Button variant="contained" sx={{ backgroundColor: '#04a46c' }} onClick={startCycle} fullWidth>
              Start Cycle
            </Button>
          </Grid>
          <Grid item xs={4} sm={2}>
          <Paper elevation={3} sx={{ p: 1, textAlign: 'center' }}>
            <CircularProgressWithLabel value={(remainingTime)} />
          </Paper>
          </Grid>           
          <Grid item xs={4} sm={5}>
            <Button variant="contained" sx={{ backgroundColor: '#eb4545' }} onClick={sellNexoTokens} fullWidth>
              Sell Nexo
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h7">Gains</Typography>
              <Typography variant="h6" sx={{ color: 'green' }}>{gains > 0 ? `€${gains.toFixed(2)}` : '-'}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h7">Losses</Typography>
              <Typography variant="h6" sx={{ color: 'red' }}>{losses > 0 ? `€${losses.toFixed(2)}` : '-'}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Box sx={{ mt: 4 }}>
                <canvas ref={chartRef} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default App;
